import React from 'react';

import main from '../static/img/webp/new/index.webp'

const Content = () => {
    return (
        <img src={main} alt={'Цветень'} className={'img-fluid'}/>
    );
};

export default Content;