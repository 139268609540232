import React from 'react';
import moscow from '../static/img/webp/new/moscow.webp'
import music from '../static/img/webp/new/music.webp'
import mir from '../static/img/webp/new/mir.webp'
import potechudes from '../static/img/webp/new/potechudes.webp'
import festival from '../static/img/webp/new/festival.webp'

const Row = () => {
    return (
        <div className={'container my-3'}>
            <div className="d-flex align-items-center my-3">
                <div className="ms-3 my-3 pe-3">
                    <p>
                        Основное направление - объединение песенного наследия русского народа, национальной музыки и
                        современного профессионального саунд-дизайна.
                    </p>
                    <p>
                        Настоящие народные костюмы, яркая сценография, зажигательные танцевальные песни и проникновенная
                        лирика.
                    </p>
                </div>
                <img src={music} className="img-fluid w-50 p-3"
                     alt={'Основное направление - объединение песенного наследия русского народа, национальной музыки и\n' +
                         '                        современного профессионального саунд-дизайна.'}/>
            </div>
            <div className="d-flex align-items-center my-3">
                <img src={moscow} className="img-fluid w-50 p-3"
                     alt={'ЦВЕТЕНЬ сотрудничество'}/>
                <div className="ms-3 my-3 pe-3">
                    <p>
                        ЦВЕТЕНЬ сотрудничает с профессиональными московскими композиторами и аранжировщиками, одним из
                        крупнейших в России лейблов United Music Group.
                    </p>
                    <p>
                        «Мой залётка гармонист», «Матюшка», «Танечка»... эти и другие хиты, созданные ансамблем ЦВЕТЕНЬ
                        на основе русского фольклора, известны на всю страну.
                    </p>
                </div>
            </div>

            <div className="d-flex align-items-center my-3">
                <div className="ms-3 my-3 pe-3">
                    <p>
                        Лауреат этномузыкальной премии «МИРА» международного фестиваля этнической музыки и ремёсел «Мир
                        Сибири». ЦВЕТЕНЬ изучает, исследует традиционную культуру Зауралья. Акустическая программа
                        «Голос старины» полностью основана на этнографическом материале Курганской области без
                        стилизации.
                    </p>
                    <p>
                        В 2021 году вошёл 20 лучших фольклорных коллективов России по итогам всероссийского конкурса
                        Национального проекта «Культура».
                    </p>
                </div>
                <img src={mir} className="img-fluid w-50 p-3"
                     alt={'Лауреат этномузыкальной премии «МИРА» международного фестиваля этнической музыки и ремёсел «Мир\n' +
                         '                        Сибири».'}/>
            </div>
            <div className="d-flex align-items-center my-3">
                <img src={potechudes} className="img-fluid w-50 p-3"
                     alt={'Капитал-шоу «Поле чудес» на Первом канале'}/>
                <div className="ms-3 my-3 pe-3">
                    <p>
                        ЦВЕТЕНЬ - гость шоу Андрея Малахова «Привет, Андрей!» на канале Россия 1, капитал-шоу «Поле
                        чудес» на Первом канале. Участник шоу «Страна талантов» на НТВ.
                    </p>
                    <p>
                        Участник Фестиваля-марафона «Песни России» под руководством Надежды Бабкиной.
                    </p>
                </div>
            </div>
            <div className="d-flex align-items-center my-3">
                <div className="ms-3 my-3 pe-3">
                    <p>
                        Хедлайнер фестивалей «Славянский базар» в Витебске (Беларусь), «Бажовский фестиваль» в
                        Челябинске, «Вкусы России» в Москве, «Каравон» в Никольском (Татарстан) и других национальных и
                        этнических фестивалей.
                    </p>
                </div>
                <img src={festival} className="img-fluid w-50 p-3"
                     alt={'Хедлайнер фестивалей «Славянский базар» в Витебске (Беларусь)'}/>
            </div>
        </div>
    );
};

export default Row;