import React from 'react';
import {Route, Routes} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import './static/css/main.css';

import Header from "./components/Header";
import Content from "./components/Content";
import Row from "./components/Row";
import Video from "./components/Video";
import Photos from "./components/Photos";
import Contacts from "./components/Contacts";
import About from "./components/About";
import Afisha from "./pages/Afisha";

const App = () => {

    const [showButton, setShowButton] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        });
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div>
            <Header/>
            <>
                <Routes>
                    <Route path={'/'} exec element={
                        <>
                            <Content/>
                            <About/>
                            <div className={'bg-white p-lg-5'}>
                                <Row/>
                            </div>
                            <Video/>
                            <div className={'bg-white'}>
                                <Photos/>
                            </div>
                            <Contacts/>
                            {showButton && (
                                <button onClick={scrollToTop} className="back-to-top">
                                    На вверх!
                                </button>
                            )}
                        </>
                    }/>
                    <Route path={'afisha/'} exec element={<Afisha/>}/>
                    {/*<Route path={'*'} exec element={<NotFound/>}/>*/}
                </Routes>
            </>
        </div>
    );
};

export default App;