import React from 'react';

const Index = () => {
    return (
        <div className={'author'}>
            Сайт разработан: <a href={'//vk.com/malfin_beats'} target={'_blank'} rel="noreferrer" className={'text-decoration-none'}>MAL'FIN</a>
        </div>
    );
};

export default Index;