import React from 'react';
import Author from "./Author";

const Contacts = () => {
    return (
        <div className={'p-3 my-3 text-center'} id={'contacts'}>
            <h3>Контакты для организации выступлений и сотрудничества</h3>
            <div className="row p-3">
                <div className={'col'}>
                    <p className={'text-center'}>Виктор Шведов</p>
                    <a href="tel:89097253328">+7 (909) 725-33-28</a>
                    <p className={'text-center'}><a href="mailto:cvetenfolk@mail.ru">cvetenfolk@mail.ru</a></p>
                    <p className={'text-center'}><a href="https://vk.com/cvetenfolk" target="_blank" rel="noreferrer" className={'vk'}>Задать вопрос</a>
                    </p>
                </div>
            </div>
            <footer>
                {new Date().getFullYear()} © Ансамбль "Цветень"
            </footer>
            <Author/>
        </div>
    );
};

export default Contacts;