import React from 'react';
import logo from '../static/img/webp/logotip_tsveten_2018_2kr.webp'
import {HashLink} from "react-router-hash-link";
import {Link} from "react-router-dom";

const Header = () => {


    return (
        <nav className="navbar navbar-expand-lg">
            <div className="container">
                <Link className="navbar-brand" to={'/'}>
                    <img src={logo} alt="Цветень" width="140" height="48"/>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link" to={'/afisha'}>АФИША</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to={'/#about'}>О НАС</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <HashLink className="nav-link dropdown-toggle" to={"#"} role="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false">
                                Музыка
                            </HashLink>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item"
                                       href="//vk.com/music/album/-2000196092_16196092_1194542e035ace9ae3"
                                       target={'_blank'} rel={'noreferrer'}>ВК</a></li>
                                <li><a className="dropdown-item" href="//music.yandex.ru/artist/6898477"
                                       target={'_blank'} rel={'noreferrer'}>Яндекс Музыка</a></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <HashLink className="nav-link dropdown-toggle" to={"#"} role="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false">
                                Видео
                            </HashLink>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item"
                                       href="//vk.com/video/@cvetenfolk"
                                       target={'_blank'} rel={'noreferrer'}>ВК</a></li>
                                <li><a className="dropdown-item"
                                       href="//www.youtube.com/c/%D0%90%D0%BD%D1%81%D0%B0%D0%BC%D0%B1%D0%BB%D1%8C%D0%A6%D0%B2%D0%B5%D1%82%D0%B5%D0%BD%D1%8C"
                                       target={'_blank'} rel={'noreferrer'}>Ютуб</a></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <HashLink className="nav-link" to={'/#contacts'}>КОНТАКТЫ</HashLink>
                        </li>
                    </ul>
                    <div className="d-flex">
                        <a href="tel:89097253328" target={'_blank'} rel={'noreferrer'}>+7 (909) 725-33-28</a>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Header;