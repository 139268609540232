import React from 'react';
import axios from "axios";
import Loader from "../components/Loader";

const Afisha = () => {
    const [afisha, setAfisha] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [showOldPosts, setShowOldPosts] = React.useState(false);

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_API)
            .then((result) => {
                setAfisha(result.data)
                setLoading(false)
            })
    }, [])
    const currentDate = new Date();


    function checkDate(date) {
        return currentDate > new Date(date);
    }


    const filteredPosts = afisha.filter(post => (showOldPosts ? new Date(post.date) < currentDate : new Date(post.date) > currentDate))
        .sort((a, b) => new Date(a.date) - new Date(b.date));

    return (
        <div className={'container my-3'}>
            <div className="d-flex justify-content-between flex-wrap align-items-center">
                <h3>Афиша</h3>
                <button className={'btn btn-secondary pl-2'} onClick={() => setShowOldPosts(!showOldPosts)}>
                    {showOldPosts ? 'Предстоящие мероприятия' : 'Прошедшие мероприятия'}
                </button>
            </div>
            {
                loading ? [...new Array(6)].map((_, index) => <div className="d-flex justify-content-between flex-wrap"
                                                                   key={index}>
                        <Loader className={'my-2'}/>
                    </div>) :
                    filteredPosts.map((item) => (
                        <div className={`card my-2 ${checkDate(item.date) ? 'opacity-50' : ''}`} key={item.id}>
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-self-center">
                                    <div className="date text-center">
                                        <div
                                            className="month">{new Date(item.date).toLocaleDateString('ru-RU', {month: 'short'})}</div>
                                        <div
                                            className="day">{new Date(item.date).toLocaleDateString('ru-RU', {day: 'numeric'})}</div>
                                        <div className="weekday">
                                            {new Date(item.date).toLocaleDateString('ru-RU', {weekday: 'short'})}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center flex-wrap">
                                        <h4 className={'mx-3'}>{item.name}</h4>
                                        {item.is_kassy_ru &&
                                            <a href={item.is_kassy_ru} className="btn btn-secondary pl-2 my-3"
                                               target="_blank"
                                               rel={'noreferrer'}>Билет</a>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <p className={'text-end'}>{new Date(item.date).toLocaleDateString('ru-RU', {year: 'numeric'})}</p>
                            </div>
                        </div>
                    ))}
        </div>
    );
};

export default Afisha;