import React from 'react';

const Video = () => {
    return (
        <div className={'my-3 p-lg-3'} id={'video'}>
            <div className="ratio ratio-21x9">
                <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/ACIIlXRs63M?si=kMPg5MDfmUJGj57-"
                        title={'Ансамбль ЦВЕТЕНЬ - ЖИТО ЖАЛА (клип 2022)'} allowFullScreen></iframe>
            </div>
        </div>
    );
};

export default Video;