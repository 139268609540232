import React from 'react';

const About = () => {
    return (
        <div className="container text-center my-3 p-4 w-50" id={'about'}>
            <h4 className={'my-3 p-2'}>
                <b>Цветень</b> - российский профессиональный фольклорный коллектив,
                исполняющий русский фольклор в <b>этно-поп</b> стиле.
                Основан в 1999 году в Кургане. Создатель и художественный руководитель,
                лауреат премии Правительства РФ «Душа России» Стаc Белинский.
            </h4>
        </div>
    );
};

export default About;